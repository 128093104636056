<template>
  <div class="p-0">
    <div class="bg-white p-8 rounded-lg shadow-xl py-8 mt-12">
      <h3 class="text-lg font-semibold">Documentação - Inteligência Artificial</h3>
      <div class="pb-12 mt-4">

        <!-- Layout Responsivo -->
        <div class="flex min-h-screen flex-row py-8">
          <!-- Sidebar -->
          <Menu />
          <!-- Main Content -->
          <main class="flex-1 px-6 bg-gray-100">
            <h1 class="text-2xl font-bold mb-4">Configurações Avançadas</h1>
  <p class="mb-4">
    As configurações avançadas são realizadas diretamente no Duotalk para definir a transferência do atendimento com a I.A para o humano, atribuindo para o departamento correto de acordo com o contexto da conversa.
  </p>

  <h2 class="text-xl font-semibold mb-3">Transferência para Departamento</h2>
  <p class="mb-4">
    O prompt deve ser configurado para reconhecer palavras-chave ou frases específicas que marcam o contexto da conversa. Por exemplo:
  </p>
  <div class="bg-gray-50 p-4 rounded-md mb-4">
    <p class="font-mono">"Se o cliente mencionar 'problema técnico', transfira para o suporte técnico."</p>
    <p class="font-mono">"Se a conversa for sobre vendas, transfira para o Comercial."</p>
  </div>
  <p class="mb-4">
    Nas configurações do Duotalk, as intenções (departamentos) devem ser cadastradas previamente para definição no script de transferência do chatbot. 
  </p>
  <p class="mb-4">
    Para filiais/unidades, ainda não é possível usar a função de entidades-chave, ficando limitado ao uso de intenções (departamentos).
  </p>

  <h2 class="text-xl font-semibold mb-3">Encerramento de Conversa</h2>
  <p class="mb-4">
    O prompt deve deixar claro em quais cenários a conversa deve ser encerrada. Além disso, é necessário definir nas configurações do Duotalk se o chatbot possui autonomia para encerrar um atendimento ou se ele sempre deve transferir para um humano fazer uma checagem final.
  </p>

  <h2 class="text-xl font-semibold mb-3">Adição de Tags</h2>
  <p class="mb-4">
    Tags ajudam a classificar as interações e coletar dados valiosos sobre as necessidades dos clientes. Nas configurações do Duotalk, é possível definir os tipos de dados que devem ser armazenados em tags. Exemplos de tags:
  </p>
  <ul class="list-disc list-inside mb-4">
    <li><strong>Interesse:</strong> O que o cliente está buscando.</li>
    <li><strong>Veículo:</strong> Tipo de veículo de interesse, caso aplicável.</li>
    <li><strong>E-mail:</strong> Endereço de e-mail do cliente.</li>
    <li><strong>Empresa:</strong> A empresa associada ao cliente.</li>
  </ul>
          </main>
        </div>

      </div>
    </div>
  </div>
</template>
    
    
      
      <script>
      import Menu from './MenuFaqIA.vue'; // Importando o menu
      export default {
        name: 'ChatbotIA-BaseConhecimento',
        components: {
            Menu
        },
        data() {
        return {
          // Lista de perguntas e respostas, o primeiro item já vem com `active: true`
          faqs: [
            { question: "O que é Duotalk?", answer: "Duotalk é uma plataforma que oferece soluções de atendimento automatizado.", active: true },
            { question: "Como configurar a inteligência artificial?", answer: "Você pode configurar a inteligência artificial seguindo as instruções no nosso guia.", active: false },
            { question: "Quais são os planos disponíveis?", answer: "Os planos variam de acordo com o número de usuários e as funcionalidades necessárias.", active: false },
          ]
        };
      },
      methods: {
        // Alterna a visibilidade da resposta
        toggleFaq(index) {
          this.faqs[index].active = !this.faqs[index].active;
        }
      }
    };
    
    </script>
    
    
    <style scoped>

    
    .faq-item h3 {
      cursor: pointer;
      padding: 10px;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin: 5px 0;
      transition: background-color 0.3s ease;
    }
    
    .faq-item h3:hover {
      background-color: #e0e0e0;
    }
    
    .faq-item h3.active {
      background-color: #d0d0d0;
    }
    
    .faq-item p {
      margin: 10px 0;
      padding: 10px;
      background-color: #fafafa;
      border-left: 5px solid #cccccc;
    }
    </style>
   